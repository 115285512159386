import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import axios from "axios";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import { IconSearch } from "@tabler/icons";
import { useSelector, useDispatch } from "react-redux";
import { addItemToTableData } from "views/Sales-Sections/salesReturn/slice/tableDataSlice";
import {
  addItemToTableData as addItemToPos,
  updateQtyAndTotal,
} from "../salesPos/slice/tableDataPosSlice";
import { redirectUrl, baseUrl } from "config";

import MultiUnitWindow from "../salesReturn/components/MultiUnitWindow";
import BatchWindowPos from "../salesReturn/components/BatchWindowPos";
import BatchWindow from "../salesPos/components/BatchWindow";
import MultiUnitWindowPos from "../salesPos/components/MultiUnitWindowPos";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackBar } from "context/snackbarContext";

// function DrawerItems({ typ, allProduct, expiry, batchWise }) {
function DrawerItems({ typ, expiry, batchWise }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const allProductsData = useSelector(
    (state) => state.ProductsData?.data?.result
  );
  // const [allProducts, setAllProducts] = useState(allProductsData || []);
  // const [draweData, setDrawerData] = useState(allProductsData || []);
  const [allProducts, setAllProducts] = useState(allProductsData);
  const [draweData, setDrawerData] = useState(allProductsData);

  const [openModal, setOpenModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryMenuItems, setCategoryMenuItems] = useState();
  const [currProduct, setCurrProduct] = useState(null);
  const [modalTyp, setModalTyp] = useState(null);
  const itemsPerPage = 198;
  const [itemQuantities, setItemQuantities] = useState([]);
  const tableDataPos = useSelector((state) => state.tableDataPos);
  const netTotal = useSelector((state) => state.discAndTaxPos.netTotal);

  const { showSnackbar } = useSnackBar();
  useEffect(() => {
    getCategory();
  }, []);
  // useEffect(() => {
  //   setDrawerData(allProduct);
  //   setAllProducts(allProduct);
  // }, [allProduct]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const itemsToRender = draweData?.slice(startIndex, endIndex);
  const regex = /^[A-Z]/;

  // const handleSearchField = () => {
  //   var updatedList = [...allProducts];

  //   updatedList = updatedList.filter((item) => {
  //     return item.ItemNameTextField.toLowerCase().includes(
  //       searchName.toLowerCase()
  //     );
  //   });

  //   setDrawerData(updatedList);
  // };
  const handleSearchField = (value) => {
    setSearchName(value);
    var updatedList = [...allProducts];

    updatedList = updatedList.filter((item) => {
      return item.ItemNameTextField.toLowerCase().includes(value.toLowerCase());
    });

    setDrawerData(updatedList);
  };

  const getCategory = () => {
    setDrawerData(allProductsData);
    setAllProducts(allProductsData);
    axios({
      method: "post",
      url: baseUrl + "/getCategoryOrSubcategory",
      data: {
        typ: "PRODUCT GROUP",
      },
      withCredentials: true,
    })
      .then((res) => {
        // to show all the products we need all menu option
        setCategoryMenuItems([
          {
            Typ: "All",
          },
          ...res.data,
        ]);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          redirectUrl();
        }
      });
  };

  const handelCategory = (value) => {
    if (value === "All") {
      setDrawerData(allProducts);
    } else {
      const filtered = allProducts.filter((name) => name.Size === value);
      setDrawerData(filtered);
    }
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const clearField = () => {
    setSearchName("");
    setDrawerData(allProducts);
  };

  const handleAddItem = (item, quantity) => {
    const existingItemIndex = tableDataPos.findIndex(
      (items) => items.ItemC === item.ItemC
    );

    if (existingItemIndex !== -1) {
      quantity = quantity ?? 1;
      const newItem = {
        ...item,
        qty: quantity,
        exp_date: dayjs().format("DD/MM/YYYY"),
      };
      newItem.total = ((quantity * newItem.Rate1) / 100) * newItem.Tax1;
      setCurrProduct(newItem);
      // if (item.Typ !== item.SecUnit) {
      if (item.Pack !== 1) {
        handleClickOpen("multiUnit");
      } else {
        quantity = quantity ?? 1;
        const updatedQty = tableDataPos[existingItemIndex].qty + quantity;
        const updatedTotal =
          ((updatedQty * item.Rate1) / 100) * item.Tax1 +
          item.Rate1 * updatedQty;
        console.log(item);

        dispatch(
          updateQtyAndTotal({
            index: existingItemIndex,
            qty: updatedQty,
            total: updatedTotal,
            taxAmt: ((updatedQty * item.Rate1) / 100) * item.Tax1,
          })
        );

        showSnackbar(
          "success",
          `Added ${item.ItemNameTextField} - ${updatedQty} X ${item.Rate1} = ${updatedTotal}`
        );
      }
    } else {
      quantity = quantity ?? 1;
      if (item) {
        const newItem = {
          ...item,
          qty: quantity,
          taxAmt: ((quantity * item.Rate1) / 100) * item.Tax1,
          exp_date: dayjs().format("DD/MM/YYYY"),
        };
        newItem.total = ((quantity * newItem.Rate1) / 100) * newItem.Tax1;

        setCurrProduct(newItem);
        // if (newItem.Typ !== newItem.SecUnit) {
        if (newItem.Pack !== 1) {
          handleClickOpen("multiUnit");
        } else if (batchWise === "YES BATCH") {
          handleClickOpen("batchWise");
        } else {
          // If neither condition is met, add the item to the table data
          if (typ === "salesReturn") {
            dispatch(addItemToTableData(newItem));
          } else {
            dispatch(addItemToPos(newItem));
            // showSnackbar(
            //   "success",
            //   `Item:${newItem.ItemNameTextField},Qty:${quantity}`
            // );
          }
        }
      }
    }
  };
  // const handleAddItem = (item, quantity) => {
  //   quantity = quantity ?? 1;
  //   if (item) {
  //     const newItem = {
  //       ...item,
  //       qty: quantity,
  //       taxAmt: ((quantity * item.Rate1) / 100) * item.Tax1,
  //       exp_date: dayjs().format("DD/MM/YYYY"),
  //     };
  //     newItem.total = ((quantity * newItem.Rate1) / 100) * newItem.Tax1;

  //     setCurrProduct(newItem);
  //     if (newItem.Typ != newItem.SecUnit) {
  //       handleClickOpen("multiUnit");
  //     } else if (batchWise == "YES BATCH") {
  //       handleClickOpen("batchWise");
  //     } else {
  //       // If neither condition is met, add the item to the table data
  //       if (typ === "salesReturn") {
  //         dispatch(addItemToTableData(newItem));
  //       } else {
  //         dispatch(addItemToPos(newItem));
  //       }
  //     }
  //   }
  // };

  // Modal open
  const handleClickOpen = (typ) => {
    setModalTyp(typ);

    setOpenModal(true);
  };

  // Modal close
  const handleClose = () => {
    setOpenModal(false);
  };

  // Function to increase the quantity for an item by index
  const increaseQuantity = (index) => {
    setItemQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = (newQuantities[index] || 1) + 1;
      return newQuantities;
    });
  };

  // Function to decrease the quantity for an item by index
  const decreaseQuantity = (index) => {
    setItemQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      const currentQuantity = newQuantities[index] || 1;
      if (currentQuantity > 0) {
        newQuantities[index] = currentQuantity - 1;
      }
      return newQuantities;
    });
  };

  return (
    <Box>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 0 }}>
          <DialogContent sx={{ p: 0 }}>
            {typ === "salesReturn" ? (
              <>
                {modalTyp === "batchWise" ? (
                  <BatchWindowPos
                    product={currProduct}
                    modalClose={handleClose}
                    batchWise={batchWise}
                    expiry={expiry}
                    typ="salesReturn"
                  />
                ) : (
                  <MultiUnitWindow
                    product={currProduct}
                    expiry={expiry}
                    modalClose={handleClose}
                    typ="salesReturn"
                  />
                )}
              </>
            ) : (
              <>
                {modalTyp === "multiUnit" ? (
                  <MultiUnitWindowPos
                    product={currProduct ? currProduct : {}}
                    modalClose={handleClose}
                    batchWise={batchWise}
                    expiry={expiry}
                    typ="salesBill"
                  />
                ) : (
                  <BatchWindow
                    product={currProduct ? currProduct : {}}
                    expiry={expiry}
                    modalClose={handleClose}
                  />
                )}
              </>
            )}
          </DialogContent>
        </DialogContent>
      </Dialog>

      <Box>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={categoryMenuItems || []}
              getOptionLabel={(option) => option.Typ}
              onChange={(e, v) => {
                handelCategory(v.Typ);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  fullWidth
                  size="small"
                  disabled
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput
              size="small"
              sx={{ width: "100%", pr: 1, pl: 2, my: 2 }}
              id="input-search-profile"
              value={searchName}
              // onChange={(e) => setSearchName(e.target.value)}
              onChange={(e) => handleSearchField(e.target.value)}
              placeholder="Search products"
              startAdornment={
                <InputAdornment position="start">
                  <IconSearch
                    stroke={1.5}
                    size="1rem"
                    color={theme.palette.grey[500]}
                  />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {searchName !== "" ? (
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={clearField}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              }
              aria-describedby="search-helper-text"
            />
          </Grid>
          {/* <Grid item xs={2}>
            <Button variant="contained" onClick={handleSearchField}>
              <IconSearch />
            </Button>
          </Grid> */}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Net Total :</Typography>
              <Typography variant="h4" sx={{ textAlign: "right" }}>
                {netTotal.toFixed(3)}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrevClick}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextClick}
          // disabled={
          //   itemsToRender?.length < itemsPerPage ||
          //   endIndex >= allProducts.length
          // }
          disabled={
            !itemsToRender ||
            itemsToRender?.length < itemsPerPage ||
            !allProducts ||
            endIndex >= allProducts?.length
          }
          endIcon={<ArrowForwardIosIcon />}
        >
          Next
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          {/* {itemsToRender.map((item, index) => { */}
          {itemsToRender &&
            Array.isArray(itemsToRender) &&
            itemsToRender?.map((item, index) => {
              return (
                <Grid item xs={6} sm={4} key={index}>
                  <Card
                    sx={{
                      p: 1,
                      width: "100%",
                      height: "160px",
                      "&:hover": {
                        backgroundColor: "#D3D3D3",
                      },
                    }}
                  >
                    <Box
                      onClick={() => handleAddItem(item, itemQuantities[index])}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "80px",
                          objectFit: "contain",
                        }}
                        src={
                          item.photo
                            ? // ? item.photo
                              item.photo == null ||
                              item.photo === "null" ||
                              // item.photo.includes("WIZZO")
                              regex.test(item.photo)
                              ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtjU0d_BQklzBkT7Hn7t48a5yaBVWIJa4i6PcFbFgt91JYcN-FPV0laysIBBD-VC-p-s&usqp=CAU"
                              : JSON.parse(item.photo).img
                            : // console.log(item.photo)
                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtjU0d_BQklzBkT7Hn7t48a5yaBVWIJa4i6PcFbFgt91JYcN-FPV0laysIBBD-VC-p-s&usqp=CAU"
                        }
                        alt="zxc"
                      />
                      <p
                        style={{
                          width: "-webkit-fill-available",
                          border: "none",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          margin: "0rem",
                        }}
                      >
                        {item.ItemNameTextField}
                      </p>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="body2" color="text.secondary">
                          Price: <span>{item.Rate1}</span>
                        </Typography>
                        {/* <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            sx={{ color: 'rgb(94 53 177)', p: 0 }}
                                            onClick={() => handleAddItem(item, itemQuantities[index])}
                                            >
                                            <AddCircleIcon />
                                        </IconButton> */}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap={"2px"}
                    >
                      <IconButton
                        color="primary"
                        aria-label="Decrease quantity"
                        component="label"
                        sx={{ color: "rgb(94 53 177)", p: 0 }}
                        onClick={() => decreaseQuantity(index)}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <Typography variant="body2" color="text.secondary">
                        Qty: {itemQuantities[index] || 1}
                      </Typography>
                      <IconButton
                        color="primary"
                        aria-label="Increase quantity"
                        component="label"
                        sx={{ color: "rgb(94 53 177)", p: 0 }}
                        onClick={() => increaseQuantity(index)}
                      >
                        <NavigateNextIcon />
                      </IconButton>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
}

export default DrawerItems;
